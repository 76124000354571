import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './layout/main/main.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FrGuard } from './core/guard/fr-guard.service';
import { EnGuard } from './core/guard/en-guard.service';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'contests',
        loadChildren: () =>
          import('./pages/contests/contests.module').then((m) => m.ContestsModule),
        canActivate: [EnGuard],
      },
      {
        path: 'concours',
        loadChildren: () =>
          import('./pages/contests/contests.module').then((m) => m.ContestsModule),
        canActivate: [FrGuard],
      },
      {
        path: 'emissions',
        loadChildren: () => import('./pages/shows/shows.module').then((m) => m.ShowsModule),
        canActivate: [FrGuard],
      },
      {
        path: 'shows',
        loadChildren: () => import('./pages/shows/shows.module').then((m) => m.ShowsModule),
        canActivate: [EnGuard],
      },
      {
        path: 'animateurs',
        loadChildren: () => import('./pages/hosts/hosts.module').then((m) => m.HostsModule),
        canActivate: [FrGuard],
      },
      {
        path: 'hosts',
        loadChildren: () => import('./pages/hosts/hosts.module').then((m) => m.HostsModule),
        canActivate: [EnGuard],
      },
      {
        path: 'nouvelles',
        loadChildren: () =>
          import('./pages/contents/contents.module').then((m) => m.ContentsModule),
        canActivate: [FrGuard],
      },
      {
        path: 'categorie',
        loadChildren: () =>
          import('./pages/category/category.module').then((m) => m.CategoryModule),
        canActivate: [FrGuard],
      },
      {
        path: 'category',
        loadChildren: () =>
          import('./pages/category/category.module').then((m) => m.CategoryModule),
        canActivate: [EnGuard],
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./pages/contents/contents.module').then((m) => m.ContentsModule),
        canActivate: [EnGuard],
      },
      {
        path: 'audio',
        loadChildren: () =>
          import('./pages/contents/contents.module').then((m) => m.ContentsModule),
      },
      {
        path: 'photos',
        loadChildren: () =>
          import('./pages/galleries/galleries.module').then((m) => m.GalleriesModule),
      },
      {
        path: 'stream',
        loadChildren: () => import('./pages/stream/stream.module').then((m) => m.StreamModule),
      },
      {
        path: 'radio-circulation',
        loadChildren: () =>
          import('./pages/radio-circulation/radio-circulation.module').then(
            (m) => m.RadioCirculationModule
          ),
      },
      {
        path: 'balados',
        loadChildren: () =>
          import('./pages/podcasts/podcasts.module').then((m) => m.PodcastsModule),
        canActivate: [FrGuard],
      },
      {
        path: 'podcasts',
        loadChildren: () =>
          import('./pages/podcasts/podcasts.module').then((m) => m.PodcastsModule),
        canActivate: [EnGuard],
      },
      {
        path: 'consent',
        loadChildren: () => import('./pages/consent/consent.module').then((m) => m.ConsentModule),
      },
      {
        path: 'collection',
        loadChildren: () => import('./pages/collections/collections.module').then((m) => m.CollectionsModule),
      }
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
    data: {
      meta: {
        title: '404',
      },
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
